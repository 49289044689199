// Here you can add other styles

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.border-bm-dotted {
  border: none;
  border-bottom: 2px solid black;
  border-bottom-style: dotted;
}

.fs-small {
  font-size: 14px;
}

.fs-smaller {
  font-size: 12px;
}

.react-confirm-alert-button-group :nth-child(1) {
  background: $success !important;
}

.react-confirm-alert-button-group :nth-child(2) {
  background: $danger !important;
}
.bg-dark {
  background-color: rgba(16, 129, 64, 0.8) !important;
}

.btn-info {
  background-color: #80b342;
  color: white;
  &:hover {
    background-color: #108140;
  }
}

.edit-button {
  border: 1px solid #80b342 !important;
  background-color: transparent !important;
  color: black;
  font-size: small;
  box-shadow: none !important;
}

.custom-prefix__option:hover {
  background-color: #f6f9f1;
}
.react-confirm-alert-overlay {
  z-index: 1000000 !important;
}
.react-confirm-alert {
  z-index: 1000000001;
}
.btn-group{
  width:35px;
  height:35px
}
.container{ max-width: 2040px !important; }
.container-lg{ max-width:2040px;}


html, body {
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
  height: 100%; /* Ensure the body height matches the viewport */
  overflow-x: hidden; /* Prevent x axis scrolling and extra space */
}

body {
  transform: scale(0.85); /* Scale the page to 80% */
  transform-origin: top left; /* Scale from the top-left corner */
  width: 117.65%; /* Adjust width to match the scaled content */
  height: 117.65%; /* Adjust height to match the scaled content */
  display: flex; /* Ensure the body takes up full space */
  flex-direction: column; /* Arrange content in a column */
  background-color: #ebedef;
}


